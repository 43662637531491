import * as React from "react";
import { SEO } from "../seo/seo"; // Ensure this path is correct
import Layout from "../layouts";
import { Typography } from "@mui/material";

const NotFoundPage = (): JSX.Element => {
  return (
    <Layout>
      <SEO />
      <div style={{marginTop: "10rem"}}>
          <Typography variant="h4" component="h2" align="center">
            Not Found
          </Typography>
          <Typography variant="h5" component="h3" align="center">
            You just hit a route that doesn&#39;t exist...
          </Typography>
          </div>
    </Layout>
  );
};

export default NotFoundPage;
